import { useEffect, useRef, useCallback } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

const SessionChecker = () => {
  const navigate = useNavigate();
  const intervalRef = useRef(null);
  const isCheckingRef = useRef(false);

  // Use useCallback to memoize the handler
  const handleSessionExpiration = useCallback(() => {
    navigate("/");
  }, [navigate]);

  // Debounce the session check to prevent rapid consecutive checks
  const checkSession = useCallback(
    debounce(async () => {
      if (isCheckingRef.current) return;

      try {
        isCheckingRef.current = true;
        const session = await Auth.currentSession();
        if (!session?.accessToken) {
          handleSessionExpiration();
        }
      } catch (error) {
        if (error !== "No current user") {
          handleSessionExpiration();
        }
      } finally {
        isCheckingRef.current = false;
      }
    }, 1000),
    [handleSessionExpiration]
  );

  useEffect(() => {
    // Set up Hub listener for auth events
    const unsubscribe = Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "tokenRefresh_failure":
        case "signOut":
          handleSessionExpiration();
          break;
        default:
          break;
      }
    });

    // Initial session check
    checkSession();

    // Set up interval with ref
    intervalRef.current = setInterval(() => {
      // Only trigger check if no check is currently in progress
      if (!isCheckingRef.current) {
        checkSession();
      }
    }, 60000); // Check every minute

    // Cleanup function
    return () => {
      unsubscribe();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      checkSession.cancel(); // Cancel any pending debounced calls
    };
  }, [checkSession, handleSessionExpiration]);

  // This component doesn't render anything
  return null;
};

export default SessionChecker;
